/* Stile per la tabella */
table {
    width: 95%;
    margin: auto;
    border-collapse: collapse;
}

.table-container {
    overflow-x: auto;
}

.dark-table {
    border-collapse: collapse;
    width: 100%;
    color: #cbcbcb;
}

.dark-table th, .dark-table td {
    border: 1px solid #444;
    padding: 8px;
    text-align: left;
}

.dark-table th {
    background-color: #222;
}

.dark-table tbody tr:nth-child(even) {
    background-color: #333;
}

.dark-table tbody tr:hover {
    background-color: #555;
}

.search-input {
    padding: 8px;
    text-align: left;
    color: white;
}