.home-images{
    width: 1000px;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.3;
}

.home{
}

/* Start Global Rules */
* {
    box-sizing: border-box;
}
body {
    font-family: 'Open Sans', sans-serif;
}
a {
    text-decoration: none;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}
/* Small */
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
/* Medium */
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
/* Large */
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
/* End Global Rules */

.wrapper-news {
    width: 100%;
    padding-top: 32px;

    /*
    display: grid;
    column-gap: 32px;
    row-gap: 32px;
    */

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
}

.article {
    width: calc(33% - 2rem);
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-decoration: none!important;
    border-radius: 10px;

    outline: 0px solid #00000000;
    transition-duration: 0.1s;
}

.article:hover {
    outline: 3px solid var(--color-red);
}

.article img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
}

.article-contents {
    color: var(--text-white);
    background-color: var(--color-background-light);
    border-radius: 0px 0px 10px 10px;
    padding: 1.5rem;
}

.article-contents h4{
    margin: 0;
    color: var(--text-white-light);
    font-weight: normal;
}

.article-contents h2{
    font-size: 20pt;
    margin: 1rem 0px;
}

.article-contents p {
    font-weight: normal;
}



/* Classe speciale per il primo articolo */
.article:nth-child(1) {
    width: 100%;
    flex-direction: row;
}

.article:nth-child(1) img {
    height: 100%;
    width: 66%;
    border-radius: 10px 0px 0px 10px;
}

.article:nth-child(1) .article-contents{
    border-radius: 0px 10px 10px 0px;
}

/* Classe speciale per il secondo e terzo articolo */
.article:nth-child(2), .article:nth-child(3) {
    width: calc(50% - 1rem);
}


@media only screen and (max-width: 1000px) {
    .article {
        width: 100%!important;
        flex-direction: column!important;
    }

    .article img {
        width: 100%!important;
        height: auto!important;
        border-radius: 10px 10px 0px 0px!important;
    }
}

