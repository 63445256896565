footer {
    width: 100%;
    background-color: #050505;
}

footer ul {
    padding: 64px;
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    list-style: none;
}


footer li {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.footer-icon {
    background-color: var(--color-red);
    min-width: 36px;
    height: 36px;
    border-radius: 100px;

    position: relative;
    top: 5px;
    left: 5px;
}

.footer-icon img {
    height: 32px;
    position: relative;
    top: -5px;
    left: -5px;
    filter: drop-shadow(2px 2px 3px #000000aa);
}

footer li h2 {
    margin: 0;
    color: var(--color-red-light);
}

footer li p {
    margin: 0;
}

.fade-reverse {
    background-image: url('/public/img/black_stone_fade_reverse.svg');
    position: relative;
    top: -1px;
}

@media only screen and (max-width: 1200px) {
    footer ul {
        padding: 48px;
    }
}