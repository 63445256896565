:root {
	--arrow-width: 40px;
	--arrow-pad: 15px;
	--image-height: 300px
}

#rules-image {
	width: 100%;
	margin: 32px 0px;
	border-radius: 10px;
	height: var(--image-height);
	object-fit: none;
	margin-top: 0;
}

.point-spacer {
	display: inline-block; 
	font-size: 13px; 
	letter-spacing: 3px;
	color: gray;
	user-select: none;
	font-family: monospace;
}

.rule-penalty {
	float:right;
	font-style: italic;
	color: lightgray;
}

#rules-title {
	display: inline-block;
	margin-right: 0;
}

#game-name {
	font-size: 40px;
	color: var(--color-red-light);
	margin-left: auto;
	float: right;
}

#rules {
	font-size: 20px;
	margin-left: 10px;
}

#rules li {
	margin-bottom: 10px;
}

#rules ::marker {
	color: var(--color-red-light);
}

#rules-sect {
	display: flex;
	justify-content: space-around;
}

#rules-center {
	flex-shrink: 0;
}

#arrow-right {
	padding-right: var(--arrow-width);
	padding-left: var(--arrow-pad);
}

#arrow-left {
	padding-left: var(--arrow-width);
	padding-right: var(--arrow-pad);
}

#arrow-left img, #arrow-right img {
	width: var(--arrow-width);
	height: 55%;
	margin-top: var(--image-height);
	color: var(--color-red-light);
}

#arrow-left img {
	transform: scaleX(-1);
}