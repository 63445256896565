.container-player-info {
    width: 95%;
    max-width: 900px; /* Imposta una larghezza massima per garantire che il contenuto non diventi troppo largo su dispositivi di grandi dimensioni */
    margin: 0 auto; /* Centra il contenitore orizzontalmente */
    border-radius: 15px; /* Bordo tondo */
    background-color: #3f3f3f; /* Colore di sfondo */
    color: #cecece; /* Colore del testo */
    font-size: 18px;
    padding: 20px; /* Spazio interno per migliorare la leggibilità */
    box-sizing: border-box; /* Include il padding e il bordo nella larghezza dichiarata */
}

.info {
    margin-bottom: 10px; /* Spazio tra le righe */
    display: block;
}

.info span {
    font-weight: bold; /* Testo in grassetto */
}

/* Rendere il layout responsive */
@media only screen and (max-width: 600px) {
    .container-player-info {
        width: 90%; /* Modifica la larghezza del contenitore per adattarsi a schermi più piccoli */
        max-width: none; /* Rimuovi la larghezza massima */
        font-size: 14px;
    }
}