

.header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.container{
    display : flex;
    justify-content : space-around;
    align-items : center;
    width : 100%;
    height : 70px;
    border-bottom : 2px solid #ad1b2c;
}

.logo a{
    text-decoration : none;
    color : #ad1b2c;
    font-weight : bold;
    font-size : 20px;
    margin-left : -37px;
    cursor : pointer;
}

.nav a{
    text-decoration : none;
    padding-bottom : 2px;
    margin-right : 50px;
    color : #ad1b2c;
    font-size: 25px;
    font-weight : 550;
    position : relative;
    cursor : pointer;
}

.nav a::before{
    position : absolute;
    content : '';
    top: 100%;
    left : 0;
    height : 2px;
    width : 0%;
    background : #ad1b2c;
    transition : all ease 0.4s;
}

.nav a:hover::before{
    width : 100%;
}