@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;600&display=swap');

:root {
    --color-red-light: #ff3a51;
    --color-red: #ad1b2c;
    --color-red-dark: #1b0101;
    --text-white: #FAFAFA;
    --text-white-light: #A3A3A3;
    --shadow-black: #00000078;
    --color-background: #171717;
    --color-background-light: #262626;
}

::selection {
    background-color: var(--color-red-light);
    color: var(--text-white);
}

/* Custom scrollbar*/
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--color-red-dark);
}

::-webkit-scrollbar-thumb {
    background: var(--color-red-light);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-red);
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Dosis', sans-serif;
    background-color: var(--color-red-dark);
    color: var(--text-white);
    scroll-behavior: smooth;
}

*{
    box-sizing: border-box;
    /*cursor: url("/public/img/Wilson_cursore_mini.png"), auto!important;*/
}

a {
    color: var(--color-red-light);
    text-decoration: none;
    font-weight: bold;
    transition: 0.3s;
}

a:hover {
    color: var(--color-red);
    text-decoration: underline;
}

header {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

header #background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

header #logo {
    position: relative;
    z-index: 1;
    max-width: 90%;
    height: 70%;
    filter: drop-shadow(5px 5px var(--shadow-black));
    animation: logo_fade_in 1s forwards;
}

header a #logo {
    max-width: 100%;
    height: 100%;
}

@keyframes logo_fade_in {
    0% {
        opacity: 0.5;
        transform: translateY(125%) scale(1.25);
    }
    100% {
        opacity: 1;
        transform: translateY(0%) scale(1);
    }
}

.background_gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#00000000 20%, #ad1b2c 100%);
}

.background-fade {
    width: 100%;
    background-image: url('/public/img/black_stone_fade.svg');
    background-size: 256px;
    min-height: 40px;
    z-index: 2;
    position: relative;
    bottom: -1px;
}

#fade {

    z-index: 1;
    filter: drop-shadow(0px 0px 16px var(--shadow-black));
}

.info-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
    gap: 32px;
    color: var(--text-white);
    max-width: 90%;
    position: relative;
    top: -32px;
    margin-top: 32px;
}

.info-box a {
    height: 48px;
    width: 48px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 8px #00000077);
}

.info-box a svg {
    color: var(--text-white);
    transition: 0.2s;
}

.info-box a:hover {
    background-color: var(--text-white)!important;
    filter: drop-shadow(0px 4px 12px #00000077);
    width: 64px;
}

.info-box a:hover svg {
    color: var(--social-color);
    fill: currentColor;
    transform: scale(1.2);
}

.info-box a:active svg {
    color: var(--social-color);
    transform: scale(0.8);
}

.info-box p{
    box-sizing: border-box;
    background-color: var(--color-background-light);


    padding: 0px 32px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    font-size: large;
    box-shadow: 0px 4px 8px var(--shadow-black);
    cursor: pointer;
    user-select: none;
    transition: 0.3s;
}

.info-box p:hover {
    background-color: var(--text-white);
    color: var(--color-background);
}

.section {
    max-width: 1300px;
    margin: auto;
}

.section-title {
    border-left: 5px solid var(--color-red);
    padding-left: 8px;
}

.section-title h1 {
    margin: 0;
}

.section-title h3 {
    color: var(--color-red-light);
    margin: 0;
}

main {
    width: 100%;
    background: var(--color-background);
    background-size: 256px;
    padding: 64px;
}

@media only screen and (max-width: 1200px) {
    main {
        padding: 48px;
    }
}